import React from "react"
import { Container, Row, Col } from "reactstrap"
import { FaThumbsUp } from "react-icons/fa"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image/withIEPolyfill"
import styled from "styled-components"

let StyledImg = styled((props) => <Img {...props} />)`
  perspective: 1500px;
  perspective-origin: left center;
  overflow: visible !important;
  picture,
  img {
    transform: rotateY(-35deg) rotateX(15deg);
    box-shadow: 25px 60px 125px -25px rgba(80, 102, 144, 0.1),
      16px 40px 75px -40px rgba(0, 0, 0, 0.2);
    border-radius: 0.625rem;
    transition: 1s !important;
    &:hover {
      transform: rotateY(-30deg) rotateX(15deg);
    }
  }
`

let Benefit = ({ title, content }) => (
  <div className="col d-flex align-items-start mb-4">
    <div className="icon-square bg-light text-dark flex-shrink-0 mr-3">
      <FaThumbsUp size={30} className="text-primary" />
    </div>
    <div>
      <h4> {title}</h4>
      <p className="m-0 text-muted">{content}</p>
    </div>
  </div>
)

let Benefits = ({ data }) => (
  <Container className="py-5">
    <Row className="d-flex align-items-center">
      <Col md="6">
        <div className="mb-4">
          <h2 className="text-primary">UNSERE WERTE</h2>
        </div>
        <Benefit
          title="Grundversorgung für alle"
          content="Der Hausarzt im klassischen Sinne ist ein Arzt für die ganzen Familie. Betreuung aller Patienten vom Kleinkind, Erwachsenen alle Altersgruppen bis zur Palliativbegleitung."
        />
        <Benefit
          title="Personenbezogener Ansatz"
          content="Jeder Mensch ist sein eigenes Universum. Individuelle Beratung und Betreuung steht bei uns an erster Stelle."
        />
        <Benefit
          title="Koordination &amp; Kooperation"
          content="Der Allgemeinarzt ist Dreh- und Angelpunkt für Behandlung &amp; Diagnose zwischen Fachärzten und Klinken. Wir erfüllen die Aufgabe, durch Besuche bei Fachärzten und Klinken zu begleiten und die Ergebnisse &amp; Resultate zu besprechnen. Die Zusammenarbeit mit Therapeuten verschiedenster Gebiete (u.a. Physio-, Ergo-, Psycho-Therapie) sowie mobilen Diensten (Kranken-Rundumpflege, 24-Stunden-Betreuung) ist für die Rundumversorgung wichtig!"
        />
      </Col>
      <Col md="6">
        <StyledImg
          fluid={data.file.childImageSharp.fluid}
          objectFit="contain"
          objectPosition="50% 50%"
        />
      </Col>
    </Row>
  </Container>
)

const BenefitsQuery = () => (
  <StaticQuery
    query={graphql`
      query BenefitsQuery {
        file(relativePath: { eq: "Rezeption.jpg" }) {
          id
          childImageSharp {
            fluid(maxWidth: 550) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={(data) => <Benefits data={data} />}
  />
)

export default BenefitsQuery
