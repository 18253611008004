import React from "react"
import { Container, Row, Col } from "reactstrap"
import Link from "../components/link"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { FaHome, FaBolt, FaPhoneSquare, FaClock } from "react-icons/fa"
import Box from "../components/box"
import Benefits from "../components/benefits"
import styled from "styled-components"
import HomeNews from "../components/homeFeatures"
import PageBanner from "../components/page-banner"
import Hr from "../components/hr"
import RedAlert from "../components/alert"
import { graphql } from "gatsby"
import Img from "gatsby-image"

let StyledBackground = styled.div`
  background: linear-gradient(to bottom, #f9fbfd 0, #fff 100%);
`

let Service = ({ title, Icon = FaHome, target = "/" }) => (
  <Col xs={12} md={4} className="mb-3">
    <Link to={target}>
      <Box>
        <Icon size={30} />
        <h4 className="mt-3">{title}</h4>
      </Box>
    </Link>
  </Col>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "logo.webp" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

let Index = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <PageBanner>
      <Container>
        <Row>
          <Col md={12}>
            <Img
              fluid={data.file.childImageSharp.fluid}
              style={{ width: "100%", height: "200px" }}
              imgStyle={{ objectFit: "contain" }}
              fadeIn={false}
            />
          </Col>
        </Row>
      </Container>
    </PageBanner>
    <Container className="pt-4">
      <div className="text-center">
        <h4>Herzlich Willkommen</h4>
      </div>
    </Container>
    {/* <Container className="pt-4">
      <RedAlert>
        <h5>Folgende Punkte sind während der Corona-Krise zu beachten:</h5>
        <ul>
          <li>
            Bei Betreten und Verlassen sind die Hände zu waschen und zu
            desinfizieren!
          </li>
          <li>Sicherheitsabstand von mind. 1 m zu Personal und Mitpatienten</li>
          <li>Telefonische Voranmeldung ist notwendig!</li>
        </ul>
      </RedAlert>
    </Container> */}
    <Hr />
    <HomeNews />
    <Container className="py-5">
      <h2 className="text-center mb-4">Direkt zu:</h2>
      <Row>
        <Service title="Öffnungszeiten" Icon={FaClock} target={"/ordinationszeiten"} />
        <Service
          title="Bereitschaft"
          Icon={FaBolt}
          target={"/bereitschaftsdienste"}
        />
        <Service title="Kontakt" Icon={FaPhoneSquare} target={"/kontakt"} />
      </Row>
    </Container>
    <StyledBackground>
      <Benefits />
    </StyledBackground>
  </Layout>
)

export default Index
