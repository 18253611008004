import React from "react"
import { Container, Col, Row } from "reactstrap"
import styled from "styled-components"
import { FaExclamationCircle } from "react-icons/fa"
import impfFile from "../assets/files/201230-Schutzimpfung-Covid19-Formular.pdf"
let StyledFeature = styled.div`
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.15);
  transition-duration: 0.25s;
  &:hover {
    box-shadow: 0 5px 25px rgba(0, 0, 0, 0.2);
  }
  margin-bottom: 1em;
  background: ${(props) =>
    props.important ? props.theme.primary : "transparent"};
  color: ${(props) => (props.important ? "white" : "inherit")};
  a {
    background: ${(props) => (props.important ? "white" : "inherit")};
  }
`

const showFile = (file) => {
  window.open(file, "_blank")
}

let Feature = ({ title, important, children }) => (
  <StyledFeature important={important}>
    <Row>
      <Col md={12} className="mb-12">
        <div className="p-3">
          <h5>
            <FaExclamationCircle /> {title}
          </h5>
          {children}
        </div>
      </Col>
    </Row>
  </StyledFeature>
)

let HomeNews = () => {
  return (
    <Container className="py-5">
        <Feature title="Wegen Urlaub geschlossen: 13.12.2023 - 07.01.2024">
        <p>
          Nächste Ordination am <strong>08.01.2024</strong> ab 07:30 Uhr. Vertretung hat:
          <ul><li>Dr. Polak - Nestelbach - 03133/2244</li></ul>
        </p>
      </Feature>
       {/* <Feature title="Corona-Impfung für Kinder" important={true}>
       <p>
          Corona-Impfungen mit dem Impfstoff Pfizer, für Kinder <u>von 5 - 18 Jahren</u> können bei uns{" "}
          <strong>KOSTENLOS nach Terminvereinbarung</strong> durchgeführt
          werden.{" "}
        </p>
      </Feature>
      <Feature title="Aktuell: Ausfall Kinderimpfungen in Schulen">
        <p>
          Kinderimpfungen, die aufgrund der aktuellen Lage <u>nicht</u> in der
          Schule stattfinden können, können bei uns{" "}
          <strong>KOSTENLOS nach Terminvereinbarung</strong> durchgeführt
          werden.{" "}
        </p>
      </Feature>
      <Feature
        title="Information zur Corona-Impfung in St. Marein bei Graz"
        important={true}
      >
        <p>
          Es ist soweit - die Schutzimpfung gegen Corona kann nun auch beim
          jeweiligen Hausarzt durchgeführt werden! Folgendes ist dazu notwendig:
        </p>
        <ol>
          <li>
            Es muss eine <strong>Online Anmeldung</strong> auf der Plattform{" "}
            <strong>"Steiermark impft"</strong>
            <strong>
              {" "}
              (
              <a
                href="https://anmeldung.steiermark-impft.at"
                target="_blank"
                rel="noreferrer"
              >
                https://anmeldung.steiermark-impft.at
              </a>
              )
            </strong>{" "}
            erfolgt sein
          </li>
          <li>
            Bei dieser Anmeldung muss die Option{" "}
            <strong>Impfen beim Hausarzt</strong> angekreuzt worden sein
          </li>
        </ol>
        <p>
          <strong>
            Der Hausarzt kann nur dann die Impfung für den Patienten in die
            jeweilige Ordination bestellen!
          </strong>
        </p>
        <p>
          <strong>
            Mittwoch ist fixer Impftag mit Biontech-Pfizer. Bei Impfwunsch rasche Einbestellung für Restdosen möglich. 
            Bitte bei Interesse in Ordination auf Ersatzliste setzen lassen. Um lange Wartezeiten zu vermeiden bitte um Terminvereinbarung. 
            Notfälle werden natürlich auch ohne Termine behandelt.
          </strong>
        </p>
        <p>
          Die <strong>Impftermine</strong> werden vom Hausarzt festgelegt und
          richten sich nach dem
          <strong> gesetzlich vorgegebenen Reihungsschema</strong> (begonnen
          wird mit der Altersklasse 80+).
        </p>
        <p>
          Es ist sinnvoll, dass sich alle Menschen ab dem{" "}
          <strong>12. Lebensjahr</strong>, die sich gegen Corona impfen lassen
          wollen, egal welchen Alters, schon jetzt auf der Plattform{" "}
          <strong>"Steiermark impft"</strong>{" "}
          <strong>
            (
            <a
              href="https://anmeldung.steiermark-impft.at/"
              target="_blank"
              rel="noreferrer"
            >
              https://anmeldung.steiermark-impft.at
            </a>
            )
          </strong>{" "}
          online anmelden, damit die Impfstoffbestellung besser organisiert
          werden kann.
        </p>
        <ul>
          <li>
            Wer nicht in eine Impfstraße weiter weg fahren will, sollte die
            Option <strong>Impfen beim Hausarzt</strong> wählen.
          </li>
          <li>
            Wer mit der Online Anmeldung Probleme hat (z.B. kein Internet)
            bekommt Unterstützung in der Gemeinde oder auch in meiner
            Ordination.
          </li>
          <li>
            Ein <strong>Aufklärungs/- Einwilligungsbogen</strong> sollte
            unterschrieben zur Impfung mitgebracht werden (Formular bei mir in
            der Ordination vorab erhältlich) oder hier{" "}
            <strong>
              <a
                href={impfFile}
                target="_blank"
                rel="noreferrer"
                onClick={() => showFile(impfFile)}
              >
                Aufklärungs/- Einwilligungsbogen hier herunterladen
              </a>
            </strong>{" "}
          </li>
        </ul>
        <p>
          Auch die <strong>ecard</strong> ist mitzubringen. Der{" "}
          <strong>Eintrag in den elektronischen Impfass</strong> erfolgt durch
          meine Ordination. Die <strong>Impfung ist Gratis</strong> (sowohl
          Impfstoff als auch Impfen)Am Impftag erfolgt ein{" "}
          <strong>Aufklärungsgespräch und die Impfung</strong>, nach der Impfung
          sollte man noch ca. <strong>20min. zur Nachbeobachtung</strong> vor
          Ort bleiben.
        </p>
      </Feature> */}
      <Feature title="Neue Testmethoden" important={true}>
        <ul>
          <li>Corona-Virus Diagnostik</li>
          <li>Antigen-Schnellstest (Ergebnis in 20 Minuten)</li>
          <li>
            PCR-Test-Abnahme{" "}
            <strong>nach telefonischer Voranmeldung möglich</strong>
          </li>
          <li>Antikörper Schnelltest</li>
        </ul>
      </Feature>
    </Container>
  )
}

export default HomeNews
