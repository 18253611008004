import React from "react"
import styled from "styled-components"

const Background = styled.div`
  background: linear-gradient(210deg, #581816 0%, #7b1518 100%);
  color: white;
  padding: 2rem 0;
  clip-path: polygon(0 0, 100% 0, 100% 86%, 0% 100%);
  text-align: center;
`

let PageBanner = ({ children }) => <Background>{children}</Background>

export default PageBanner
