import React from "react"
import styled from "styled-components"

let StyledRedAlert = styled.div`
  color: white;
  background: ${(props) => (props.dark ? "#701416" : "#6c757d")};
  padding: 1em;
  font-weight: normal;
  font-family: "Roboto", sans-serif;
  margin: 1em 0;
`

let RedAlert = ({ dark, children }) => (
  <StyledRedAlert dark={dark}>{children}</StyledRedAlert>
)

export default RedAlert
